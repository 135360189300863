/** --------------------------------------------------
* 
*   THEME SCRIPT
*
*   TABLE OF CONTENTS
*
*  -------------------------------------------------- */

(function($) {

  "use strict";

	$(document).ready(function() {

		// use select2 for select element
	    $('#select-station').select2({
	    	placeholder: 'Select your station',
	    	minimumResultsForSearch: Infinity
	    });

	    // get url parameter to filter list
	    var id = getUrlParameter('cid');
	    if (id) {
	    	$('.page-content > div').each(function(){
	    		if ($(this).attr('data-id') != id) {
	    			$(this).addClass('hidden');
	    		}
	    	});
	    }
	});


})(jQuery);

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};